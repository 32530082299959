@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #0b0132
}

@import url(https://fonts.googleapis.com/css2?family=Russo+One&family=Sora:wght@300&display=swap);

*{
    font-family: 'Sora', sans-serif;
}
/* 
#waving-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: -1;
  } */


.banner-video-overlay {
    z-index: -1;
    background-image: url('/public/overlay.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    border-radius: 0;
}


  
  